export function urlToRouteObject(url) {
  try {
    let path, query = {}
    if (typeof URL === 'function') {
      let origin
      if (typeof window === 'undefined') {
        origin = 'https://m.shein.com'
      } else {
        origin = window.location.origin
      }
      const parsedUrl = new URL(url, origin)
      path = parsedUrl.pathname
      for (const [key, value] of parsedUrl.searchParams) {
        query[key] = value
      }
    } else {
      // 兼容不支持URL 对象的浏览器
      const match = url.match(/([^?]+)\??(.*)/)
      if (match && match.length > 0) {
        path = match[1]
        const queryString = match[2]
        if (queryString) {
          const pairs = queryString.split('&')
          for (const pair of pairs) {
            const [key, value] = pair.split('=')
            query[decodeURIComponent(key)] = decodeURIComponent(value)
          }
        }

      }
    }
    return {
      name: 'page_goods_detail',
      path,
      query
    }
  } catch(e) {
    console.error(e)
  }
}

let cachedMobileOS
export const  getMobileOS = () => {
  if (cachedMobileOS) return cachedMobileOS
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return cachedMobileOS = 'iOS'
  }
  if (/android/i.test(userAgent)) {
    return cachedMobileOS = 'Android'
  }
  return cachedMobileOS = 'Unknown'
}

export const htmlDecode = function (text, isHtml) {
  if (typeof text === 'string' && text) {
    if (isHtml) {
      return text.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ').replace(/&#39;/g, '\'').replace(/&quot;/g, '"')
    } else if (typeof document !== 'undefined') {
      let temp = document.createElement('div')
      temp.innerHTML = text
      const output = temp.innerText || temp.textContent
      temp = null
      return output.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&')
    }
  }
  return ''
}

export const queryString = {
  extract: function (str) {
    return str.split('?')[1] || ''
  },
  parse: function (str) {
    // Create an object with no prototype
    // https://github.com/sindresorhus/query-string/issues/47
    const ret = Object.create(null)

    if (typeof str !== 'string') {
      return ret
    }

    str = str.trim().replace(/^(\?|#|&)/, '')

    if (!str) {
      return ret
    }

    str.split('&').forEach(function (param) {
      const parts = param.replace(/\+/g, ' ').split('=')
      // Firefox (pre 40) decodes `%3D` to `=`
      // https://github.com/sindresorhus/query-string/pull/37
      let key = parts.shift()
      let val = parts.length > 0 ? parts.join('=') : undefined

      key = decodeURIComponent(key)

      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      val = val === undefined ? null : decodeURIComponent(val)

      if (ret[key] === undefined) {
        ret[key] = val
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val)
      } else {
        ret[key] = [ret[key], val]
      }
    })
    return ret
  },
  stringify: function (obj) {
    return obj
      ? Object.keys(obj)
        .sort()
        .map(function (key) {
          const val = obj[key]

          if (val === undefined) {
            return ''
          }

          if (val === null) {
            return key
          }

          if (Array.isArray(val)) {
            const result = []

            val.slice().forEach(function (val2) {
              if (val2 === undefined) {
                return
              }

              if (val2 === null) {
                result.push(key)
              } else {
                result.push(key + '=' + val2)
              }
            })

            return result.join('&')
          }

          return key + '=' + val
        })
        .filter(function (x) {
          return x.length > 0
        })
        .join('&')
      : ''
  },
  convertHref: function (obj) {
    let queryObj = this.parse(location.search)
    Object.assign(queryObj, obj)
    return Object.keys(queryObj).length ? location.pathname + '?' + this.stringify(queryObj) : location.pathname
  }
}

export const convertUrl = function (obj, _url = '') {
  const url = _url.split('?')
  let search
  let pathname
  if (url) {
    pathname = url[0]
    search = url[1]
  }
  let queryObj = queryString.parse(search)
  Object.assign(queryObj, obj)
  return Object.keys(queryObj).length ? pathname + '?' + queryString.stringify(queryObj) : pathname
}


export const getGoodsUrl = function (name = '', id) {
  // 拼接商品详情路由
  const url = (
    '/' +
    name
      .replace('/', '-')
      .split(' ')
      .join('-') +
    '-p-' +
    id +
    // '-cat-' +
    // cat_id +
    '.html'
  )
  return replaceHrefSpeCha(url)
}

function replaceHrefSpeCha(href) {
  if (typeof href !== 'string') return
  let hrefOrigin = href && href.match(/^http(s)?:\/\/(.*?)\//g)
  if (hrefOrigin) {
    hrefOrigin = hrefOrigin[0]
    if (hrefOrigin && !hrefOrigin.match('\.shein\.') && !hrefOrigin.match('\.romwe\.')) {
      return href
    }
  }
  const pathBegin = href.indexOf('://') > 0 ? href.indexOf('://') + 2 : 0
  const seachBegin = href.indexOf('?', pathBegin) > 0 ? href.indexOf('?', pathBegin) : href.length
  const protocol = href.substring(0, pathBegin)
  let pathName = href.substring(pathBegin, seachBegin)
  const search = href.substring(seachBegin, href.length)
  pathName = pathName.replace(/[><*#@$\s'"%+&]|[\u4e00-\u9fa5]/g, '-')
  href = (protocol + pathName + search).replace(/-{2,}/g, '-')
  return href
}

export const getLangInfo = () => {
  const { MUL_SITE_LANG, lang, IS_RW } = gbCommonInfo
  let langValue = {}
  let multipleLang = []
  for (let i in MUL_SITE_LANG) {
    const langInfos = MUL_SITE_LANG[i].langInfo
    const cookieInfo = {
      cookieKey: i,
      langArr: [],
      currentLang: i == 'il' ? 'ilen' : i
    }
    for (let lang in langInfos) {
      langValue[lang] = langInfos[lang].title
      cookieInfo.langArr.push(lang)
    }

    //特殊的排列处理
    if (['il', 'se', 'ca'].includes(i)) {
      cookieInfo.langArr.reverse()
    }
    multipleLang.push(cookieInfo)
  }

  // 判断当站点是否有多语设置
  let calMultipleLang = ''
  const hasMultipleLang = !IS_RW && multipleLang.find(val => val.langArr.includes(lang))
  if (hasMultipleLang) {
    hasMultipleLang.currentLang = lang
    calMultipleLang = hasMultipleLang
  }
  return { langValue, multipleLang, calMultipleLang }
}

export const getCurrencyInfo = () => {
  const { currencies, currency, lang } = gbCommonInfo
  let data = {}
  try {
    data = JSON.parse(localStorage.getItem(`currencyData_${lang}`)) || currencies[lang]
  } catch (e) {
    data = currencies[lang]
  }
  const currenciesList = [] // 货币列表
  let defaultCurrency = {} // 默认货币
  let showCurrencyInfo = {} // 展示的货币
  Object.keys(data).forEach((k) => {
    if (data[k].default == 1) {
      defaultCurrency = data[k]
    } else {
      currenciesList.push(data[k])
    }
  })
  // 将默认货币置顶在第一位
  currenciesList.unshift(defaultCurrency)

  showCurrencyInfo = data[currency] || defaultCurrency
  return { defaultCurrency, currenciesList, showCurrencyInfo }
}

// 旧的属性拼接
export const calOldGoodsAttr = ({ color, goods_std_attr, size }, vm) => {
  const { language } = vm
  let txtArr = []
  if (color) {
    txtArr.push(`${language.SHEIN_KEY_PWA_16265}:${color}`)
  }
  if (goods_std_attr) {
    txtArr.push(`${language.SHEIN_KEY_PWA_16477}${goods_std_attr} / ${size}`)
  } else if (size) {
    txtArr.push(`${language.SHEIN_KEY_PWA_16477}${size}`)
  }
  return txtArr.join(' / ')
}

/**
 * @description 百分比数值转化
 * @param {*} num 
 * @returns 
 */
export const percentNum = (num) => {
  return `${parseInt(100 - 100 * num).toFixed(0)}%`
}

export const SHEINCountingStarSuiicon = (num, size, type = '') => {
  const iconfont = 'suiiconfont'
  var star_html = ''
  for (let i = 0; i < parseInt(num); i++) {
    star_html += `<i class="sui_icon_star_5_${size}px${type ? '_' + type : '' } ${iconfont}"></i>`
  }
  var decimal = Number(parseFloat(num).toFixed(2).split('.')[1]) || 0
  if (decimal > 0) {
    if (decimal <= 25) {
      star_html += `<i class="sui_icon_star_2_${size}px${type ? '_' + type : '' } ${iconfont}"></i>`
    } else if (decimal <= 50) {
      star_html += `<i class="sui_icon_star_3_${size}px${type ? '_' + type : '' } ${iconfont}"></i>`
    } else if (decimal <= 99) {
      star_html += `<i class="sui_icon_star_4_${size}px${type ? '_' + type : '' } ${iconfont}"></i>`
    }
  }
  for (let i = 0; i < 5 - parseInt(num) - (decimal > 0 ? 1 : 0); i++) {
    star_html += `<i class="sui_icon_star_1_${size}px${type ? '_' + type : '' } ${iconfont}"></i>`
  }
  return star_html
}

/**
 * @description 根据商品的评分，返回对应的文案
 * 评论数 [100-500] 展示100+
 * 评论数 (500-1000] 展示500+
 * 评论数1000以上展示1000+
 * @param {*} num
 */
export const SHEINCountingCommentText = (num) => {
  if (num <= 100) {
    return ''
  } else if (num <= 500) {
    return '100+'
  } else if (num <= 1000) {
    return '500+'
  } else {
    return '1000+'
  }
}

/**
 * @description 根据商品的评分销售数量，返回对应的文案
 * [0，50]：不展示
 * [50，100]：50+
 * [100，1k]：向下取整至百分位（如100+、500+）
 * [1k,10K]：向下取整至百分位（如1.1k+、1.2k+）
 * [10K以上]：10k+
 */
export const SHEINCountingSaleText = (numStr) => {
  const num = parseInt(numStr)
  if (num < 50) {
    return ''
  } else if (num <= 100) {
    return '50+'
  } else if (num <= 1000) {
    return `${Math.floor(num / 100) * 100}+`
  } else if (num <= 10000) {
    return `${Math.floor(num / 100) / 10}k+`
  } else {
    return '10k+'
  }
}

/**
 * 根据真实图片的比率映射比例字符串
 * 注意这里是 宽/高 的比率
 * */
export function closestRatio(imgRatio = 0) {
  const imgRatioMaps = [
    { key: 3 / 4, value: '3-4' },
    { key: 4 / 5, value: '4-5' },
    { key: 13 / 16, value: '13-16' },
    { key: 1 / 1, value: '1-1' }
  ]
  
  const closestObj = imgRatioMaps.reduce(function(prev, curr) {
    return (Math.abs(curr.key - imgRatio) < Math.abs(prev.key - imgRatio) ? curr : prev)
  })

  return closestObj.value 
}

/**
 * 根据真实图片的比率映射比例字符串
 * 注意这里是 高/宽 的比率
 * */
export function closestRatioHeight(imgRatio = 0) {
  const imgRatioMaps = [
    { key: 4 / 3, value: '3-4' },
    { key: 5 / 4, value: '4-5' },
    { key: 16 / 13, value: '13-16' },
    { key: 1 / 1, value: '1-1' }
  ]
  
  const closestObj = imgRatioMaps.reduce(function(prev, curr) {
    return (Math.abs(curr.key - imgRatio) < Math.abs(prev.key - imgRatio) ? curr : prev)
  })

  return closestObj.value 
}

/**
 * 根据图片地址获取图片匹配的字符串比率
 * 注意这里是 宽/高 的比率
 * */
export function getImageRatio(imgSrc = '') {
  return new Promise((resolve) => {
    const image = new Image()

    // Apollo
    const { RESOURCE_SDK } = gbCommonInfo
    if  (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
      imgSrc = imgSrc.replace(/(_square)|(_squfix)/ig, '')
    }
    
    image.src = imgSrc

    image.onload = (e) => {
      const width = e.target.width
      const height = e.target.height
      const ratioString = closestRatio(width / height)
    
      resolve(ratioString)
    }

    image.onerror = () => {
      resolve()
    }
  })
}

/**
 *
 * 根据图片地址获取携带图片比率信息的商详地址
 * @param {String} imgSrc 图片原始地址
 *
 * */
export const getProductDetailUrl = async ({  langPath = '', imgSrc, goods_url_name = 'product', goods_id, cat_id, mall_code, productDetailUrl, urlQuery = {}, getUrlWithImgRatio = false, ignoreImgRatio = false } = {}) => {
  // 商详url
  let url = langPath + (productDetailUrl || getGoodsUrl(goods_url_name, goods_id, cat_id))

  let imgRatioString = ''
  let imgRationPromise
  if (!ignoreImgRatio) {
    try {
      imgRatioString = await getImageRatio(imgSrc) 
    } catch (error) {
      console.log(error)
    }
  }

  
  // query
  const queryArr = []
  // 1. 固定的
  if (mall_code) queryArr.push(`mallCode=${mall_code}`)
  if (imgRatioString) {
    // 2. 图片比例
    queryArr.push(`imgRatio=${imgRatioString}`)
  }

  
  // 3. 自定义的
  if (urlQuery && Object.prototype.toString.call(urlQuery) === '[object Object]') {
    Object.keys(urlQuery).forEach((field) => {
      if(urlQuery[field]) {
        let params = urlQuery[field]
        params = String(params).replace(/&gt;/g, '>')
        queryArr.push(`${field}=${params}`)
      }
    })
  }

  // 拼上query
  if (queryArr.length > 0) {
    url += `${url.indexOf('?') > -1 ? '&' : '?'}${queryArr.join('&')}`
  }

  if (getUrlWithImgRatio) {
    return {
      url,
      imgRatio: imgRatioString
    }
  }

  return url
}


/**
 * 延迟函数
 * @param {*} ms 
 * @returns 
 */
export const sleep = (ms = 20) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 *
 * 滚动到 ymal 模块 (商详底部或评论)
 * @param {Boolean} isFromReview 是否来自评论
 * 
 * */
export const scrollToYmal = (isFromReview = false) => {
  if (isFromReview) {
    const reviewPopEl = document.querySelector('.goods-detail__comment-drawer')
    const reviewHeader = reviewPopEl.querySelector('.review-pop-header')
    const ymalEl = reviewPopEl.querySelector('.recommend-bottom')
    const scrollContainerEl = reviewPopEl.querySelector('.j-review-scroll-ctn')
    const headerHeight = parseInt(reviewHeader.offsetHeight)

    const anchor = ymalEl.getBoundingClientRect().top + scrollContainerEl.scrollTop - headerHeight
    scrollContainerEl.scrollTo({
      top: anchor,
      behavior: 'smooth'
    })
    return anchor
  }

  const header = document.querySelector('.j-common-logo-header')
  const ymalEl = document.querySelector('[data-floor-tab="youMayAlsoLike"]')
  const floorTab = document.querySelector('#j-floor-tab')
  const ymalElTitle = ymalEl?.querySelector('.recommend-bottom-top_title')

  if (!ymalElTitle) return

  const headerHeight = header ? parseInt(header.offsetHeight) : 0
  const floorTabHeight = floorTab ? 1.1733 * parseInt(getComputedStyle(document.documentElement).fontSize) : 0

  const anchor = ymalElTitle.offsetTop - floorTabHeight - headerHeight
  window.scrollTo({
    top: anchor,
    behavior: 'smooth'
  })

  return anchor
}

export function isElementPartiallyInViewport(el, offset = { x: 0, y: 0 }) {
  if (!el) return false

  const rect = el.getBoundingClientRect()
  const winH = window.innerHeight || document.documentElement.clientHeight
  const winW = window.innerWidth || document.documentElement.clientWidth

  const rectTop = rect.top + (offset?.y || 0)
  const rectLeft = rect.left + (offset?.x || 0)

  const vertInView = rectTop <= winH && rectTop + rect.height >= 0
  const horInView = rectLeft <= winW && rectLeft + rect.width >= 0

  return (vertInView && horInView)
}


/**
 * 图片链接扩展名前添加后缀
 * @param {string} url 图片链接
 * @param {string} suffix 后缀
 * @returns 
 */
export function patchImgUrlSuffix(url, suffix) {
  if (!url || !suffix) return url
  if (url?.indexOf(suffix) !== -1) return url
  // 匹配文件扩展名及其前的查询参数（若存在）
  const regex = /(\.[a-zA-Z0-9]{1,})((\?.*)?)$/

  // 替换操作
  return url.replace(regex, (match, p1, p2) => {
    return suffix + p1 + (p2 ? p2 : '') // 插入字符串，保留文件扩展名和查询参数
  })
}

/**
 * @description rem转px
 * @param {rem} Number 设计稿尺寸
 * @return {px} Number 转换成的尺寸，不携带单位
 * */

export const remToPx = rem => {
  let documentRootFontSize = ''
  try {
    // 比如 根节点html标签的style="font-size: 37.5px;" ； 那么 1rem = 37.5px
    documentRootFontSize = window
      ?.getComputedStyle(document?.documentElement)
      ?.fontSize?.replace('px', '') || ''
    if (typeof rem !== 'number') {
      throw new Error('rem参数为number类型')
    }
    if (!documentRootFontSize) {
      throw new Error('获取根节点html标签的字号错误，请确保isClient为true')
    }
  } catch (error) {
    console.error(error)
  } finally {
    return rem * parseFloat(documentRootFontSize)
  }
}
